body {
    color: #4b5520;
    font-size: 16px;
    background-color: #F6F1D8;
}

a {
   text-decoration: none;
   color: #4b5520;
}

li:hover a {
    color: inherit;
}

img {
    padding: 0;
}
